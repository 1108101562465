import { getRegistryURL } from "./Registry";
import { buildGetOptions, fetchWithErrorHandling, buildDeleteOptions, buildPostOptions } from "../FetchUtils";
import { useAuthUser } from "react-auth-kit";
import { isRunningOnProd } from "../../utils/Environment";

export enum EnvironmentState {
  HEALTHY = "healthy", // backend is running and reporting a healthy state
  UNHEALTHY = "unhealthy", // backend is running but does not report a healthy state
  RUNNING = "running", // instance is running but backend state is unknown
  TERMINATED = "terminated", // there is a successful workflow run indicating the termination of that instance
  PENDING_TERMINATION = "pending_termination", // there is a pending workflow run for the termination of that instance
  TERMINATION_FAILED = "termination_failed", // there is a failed or cancelled workflow run for the termination of that instance
  INITIALIZED = "initialized", // there is a successful workflow run indicating that instance should have been successfully created
  PENDING_INITIALIZATION = "pending_initialization", // there is a pending workflow run for the creation of that instance
  INITIALIZATION_FAILED = "initialization_failed", // there is a failed or cancelled workflow run for the creation of that instance
  REFRESHING = "refreshing",
  DEPLOYING = "deploying", // temporary state, internal to the Admin Panel until FOLK-11443 is resolved
  UNKNOWN = "unknown",
}

export const LOCAL_ENV: IEnvironment = {
  name: "localhost",
  creationDate: 0,
  terminationDate: undefined,
  baseURL: "http://localhost:3001/",
  stage: 1,
  backendImageId: undefined,
  isDefault: undefined,
  state: EnvironmentState.HEALTHY,
  publicIpAddress: "127.0.0.1",
  privateIpAddress: "127.0.0.1",
  gameServerCount: 0,
  activePackageId: undefined,
};

export const envTypes: { name: string; values: number[] }[] = isRunningOnProd()
  ? [
      {
        name: "Prod",
        values: [8, 9],
      },
    ]
  : [
      {
        name: "Test",
        values: [1, 2],
      },
      {
        name: "Dev",
        values: [3, 4, 5, 6, 7],
      },
      {
        name: "Prod",
        values: [8, 9],
      },
    ];

export interface IEnvironment {
  name: string;
  creationDate: number;
  terminationDate: number | undefined;
  baseURL: string;
  stage: number;
  backendImageId: string | undefined;
  previous?: IEnvironment;
  isDefault: boolean | undefined;
  state: EnvironmentState | undefined;
  publicIpAddress: string | undefined;
  privateIpAddress: string | undefined;
  gameServerCount: number | undefined;
  activePackageId: string | undefined;
}

export const listEnvironments = async (accessToken: string) => {
  return fetchWithErrorHandling(getRegistryURL() + "/v1/registry/environment/list", buildGetOptions(accessToken));
};

export const addEnvironment = async (accessToken: string, name: string, baseURL: string, stage: number) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/environment/add",
    buildPostOptions(accessToken, { name, baseURL, stage }),
  );
};

export const createEnvironment = async (accessToken: string, name: string, stage: number) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/environment/create",
    buildPostOptions(accessToken, { name, stage }),
  );
};

export const terminateEnvironment = async (accessToken: string, name: string) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/environment/terminate/" + name,
    buildDeleteOptions(accessToken),
  );
};

export const destroyEnvironment = async (accessToken: string, name: string) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/environment/destroy/" + name,
    buildDeleteOptions(accessToken),
  );
};

export const editEnvironment = async (accessToken: string, name: string, baseURL: string, stage: number) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/environment/edit",
    buildPostOptions(accessToken, { name, baseURL, stage }),
  );
};

export const setDefaultEnvironment = async (accessToken: string, name: string) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/environment/set-default",
    buildPostOptions(accessToken, { name }),
  );
};

export const evalEnvironmentState = async (accessToken: string, name: string) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/environment/eval-state/" + name,
    buildGetOptions(accessToken),
  );
};

export const deployEnvironment = async (
  accessToken: string,
  environmentName: string,
  backendImageId: string,
  backendFlavor: string,
) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/registry/environment/deploy",
    buildPostOptions(accessToken, { environmentName, backendImageId, backendFlavor }),
  );
};

export const useEnvironmentService = () => {
  const authUser = useAuthUser();

  return {
    listEnvironments: () => listEnvironments(authUser()?.access_token),
    addEnvironment: (name: string, baseURL: string, stage: number) =>
      addEnvironment(authUser()?.access_token, name, baseURL, stage),
    createDevEnvironment: (name: string, stage: number) => createEnvironment(authUser()?.access_token, name, stage),
    terminateEnvironment: (name: string) => terminateEnvironment(authUser()?.access_token, name),
    destroyDevEnvironment: (name: string) => destroyEnvironment(authUser()?.access_token, name),
    editEnvironment: (name: string, baseURL: string, stage: number) =>
      editEnvironment(authUser()?.access_token, name, baseURL, stage),
    setDefaultEnvironment: (name: string) => setDefaultEnvironment(authUser()?.access_token, name),
    evalEnvironmentState: (name: string) => evalEnvironmentState(authUser()?.access_token, name),
    deployToDevEnvironment: (environmentName: string, backendImageId: string, backendFlavor: string) =>
      deployEnvironment(authUser()?.access_token, environmentName, backendImageId, backendFlavor),
  };
};

export const getEnvironmentStageData = (stage: number) => {
  switch (stage) {
    case 1:
      return ["#800080", "#ff9fff", "TEST"];
    case 2:
      return ["#460080", "#d099ff", "TEST"];
    case 3:
      return ["#004080", "#a0ceff", "DEV"];
    case 4:
      return ["#007580", "#93f4ff", "DEV"];
    case 5:
      return ["#00801a", "#9bffb1", "QA"];
    case 6:
      return ["#648000", "#e8ffa1", "QA"];
    case 7:
      return ["#806b00", "#ffed9b", "STAGING"];
    case 8:
      return ["#804900", "#ffd49f", "STAGING"];
    case 9:
      return ["#80001e", "#ff98af", "PROD"];
    default:
      return ["#000000", "#ff0000", "INVALID"];
  }
};
