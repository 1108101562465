/* eslint-disable  @typescript-eslint/no-explicit-any */
import { json } from "@codemirror/lang-json";
import { Alert, AlertColor, Autocomplete, Button, Grid, MenuItem, Snackbar, TextField } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { LoadingOverlay } from "components/LoadingOverlay";
import { useContext, useEffect, useState } from "react";
import { IDataEntity, IDataStructure } from "../../services/backend/DataSchemaService";
import { useDataPersistenceService } from "../../services/backend/DataService";
import { AppContext } from "utils/AppContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PublishIcon from "@mui/icons-material/Publish";
import { DataExportDialog } from "./DataExportDialog";
import { DataImportDialog } from "./DataImportDialog";

const DataEntityEditor = () => {
  const dataPersistenceService = useDataPersistenceService();
  const [entityContent, setEntityContent] = useState("");
  const context = useContext(AppContext);
  const [structures, setStructures] = useState<IDataStructure[]>([]);
  const [entitiesIds, setEntitiesIds] = useState<string[]>([]);
  const [selectedStructure, setSelectedStructure] = useState<IDataStructure | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [idValue, setIdValue] = useState<string | null>(null);
  const [inputIdValue, setInputIdValue] = useState("");
  const [exportIds, setExportIds] = useState<{ id: string; name: string }[]>([]);
  const [showExportList, setShowExportList] = useState<boolean>(false);
  const [showImportOptions, setShowImportOptions] = useState<boolean>(false);
  const appContext = useContext(AppContext);

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const clearStates = () => {
    setEntityContent("");
    setIdValue(null);
    setInputIdValue("");
    setStructures([]);
    setEntitiesIds([]);
    setSelectedStructure(undefined);
    setExportIds([]);
  };

  const loadMainBranch = async () => {
    try {
      setIsLoading(true);
      clearStates();
      const { list } = await dataPersistenceService.listDataStructures();
      setStructures(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not load data-schema structures.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const loadDataEntitiesIds = async (structure: IDataStructure | undefined) => {
    try {
      if (structure) {
        setEntityContent("");
        setIdValue(null);
        setInputIdValue("");
        setIsLoading(true);
        const { entityIds } = await dataPersistenceService.listDataEntitiesId(structure.name);
        setEntitiesIds(entityIds);
      }
    } catch (e: any) {
      setEntitiesIds([]);
      setAlertMessage(e.debugMessage || "Could not load data entities ids");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const codeMirrorChangeHandler = (value: string) => {
    setEntityContent(value);
  };

  useEffect(() => {
    if (appContext?.selectedEnvironment) {
      (async () => loadMainBranch())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext?.selectedEnvironment]);

  useEffect(() => {
    context?.setTitle("Data Entity Editor");
    context?.setTargetAPI("environment");
  }, [context]);

  const loadButtonClickHandler = async () => {
    if (!selectedStructure || !idValue) {
      return;
    }

    const selectedId = idValue;
    const dataEntity = await dataPersistenceService.loadDataEntity(selectedStructure.name, selectedId);
    setEntityContent(JSON.stringify(dataEntity, null, 2));
  };

  const saveButtonClickHandler = async () => {
    if (selectedStructure)
      try {
        setIsLoading(true);
        await dataPersistenceService.storeDataEntity(selectedStructure.name, JSON.parse(entityContent));
        setAlertMessage("Data saved successfully");
        setAlertSeverity("success");
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not store the data.");
        setAlertSeverity("error");
      } finally {
        setIsLoading(false);
      }
  };

  const addToExportList = async () => {
    if (!selectedStructure || !idValue) {
      return;
    }

    exportIds.push({ id: idValue, name: selectedStructure.name });
    const ids = new Set(exportIds);
    setExportIds(Array.from(ids));
  };

  const exportData = async (): Promise<IDataEntity[]> => {
    if (exportIds.length === 0) {
      return [];
    }

    try {
      setIsLoading(true);
      setShowExportList(false);

      const data = await dataPersistenceService.exportData(exportIds.map((e) => e.id));

      if (data.errors.length > 0) {
        setAlertMessage(`Failed to export some IDs: ${data.errors.join(", ")}`);
        setAlertSeverity("error");
      } else {
        setAlertMessage("Data exported successfully!");
        setAlertSeverity("success");
      }

      return data.list;
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not export the data.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }

    return [];
  };

  const importData = async (entities: IDataEntity[]) => {
    setIsLoading(true);
    setShowImportOptions(false);

    try {
      const res = await dataPersistenceService.importData(entities);
      if (res.errors.length > 0) {
        setAlertMessage(`Failed to import some IDs: ${res.errors.join(", ")}`);
        setAlertSeverity("error");
      } else {
        setAlertMessage("Data imported successfully!");
        setAlertSeverity("success");
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not import the data.");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        <Grid item xs={11} container justifyContent={"flex-start"}>
          <TextField
            id="datastructure-selector"
            select
            label="Data Structure"
            onChange={(event) => {
              const dataStructure = structures.find((t) => t.name === event.target.value);
              setSelectedStructure(dataStructure);
              loadDataEntitiesIds(dataStructure);
            }}
            sx={{
              background: "white",
              minWidth: "240px",
              marginRight: 1,
            }}
            variant="filled"
            value={selectedStructure?.name}
          >
            {structures.map((structure) => (
              <MenuItem key={structure.name} value={structure.name}>
                {structure.name}
              </MenuItem>
            ))}
          </TextField>
          <Autocomplete
            value={idValue}
            id="data-entity-id"
            freeSolo
            options={entitiesIds}
            renderInput={(params) => <TextField {...params} label="Id" sx={{ minWidth: "240px" }} />}
            onChange={(event, newValue: string | null) => {
              setIdValue(newValue);
              if (!newValue) {
                setEntityContent("");
                setInputIdValue("");
              }
            }}
            inputValue={inputIdValue}
            onInputChange={(event, newInputValue) => setInputIdValue(newInputValue)}
          />
          <Button
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={loadButtonClickHandler}
            sx={{ marginLeft: 1 }}
            disabled={!idValue}
          >
            Load
          </Button>
          <Button
            variant="contained"
            startIcon={<PlaylistAddIcon />}
            onClick={addToExportList}
            sx={{ marginLeft: 1 }}
            disabled={!idValue || exportIds.find((e) => e.id === idValue) !== undefined}
          >
            Add to Export List
          </Button>
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={() => setShowExportList(true)}
            sx={{ marginLeft: 1 }}
            disabled={exportIds.length === 0}
          >
            Export
          </Button>
          <Button
            variant="contained"
            startIcon={<PublishIcon />}
            onClick={() => setShowImportOptions(true)}
            sx={{ marginLeft: 1 }}
          >
            Import
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            startIcon={<SaveAltIcon />}
            onClick={saveButtonClickHandler}
            size="large"
            disabled={!entityContent}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={12} justifyItems={"center"}>
          <CodeMirror value={entityContent} height="80vh" extensions={[json()]} onChange={codeMirrorChangeHandler} />
        </Grid>
      </Grid>
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <LoadingOverlay />}

      <DataExportDialog
        visible={showExportList}
        title="IDs To Export"
        data={exportIds}
        exportData={exportData}
        onDismiss={() => setShowExportList(false)}
      />

      <DataImportDialog
        visible={showImportOptions}
        title="Data Import"
        onAccept={importData}
        onDismiss={() => setShowImportOptions(false)}
      />
    </>
  );
};

export default DataEntityEditor;
