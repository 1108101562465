import { useState, useMemo, MouseEvent, ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import { DateTime } from "luxon";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import TablePagination from "@mui/material/TablePagination";
import { EnvironmentState, getEnvironmentStageData, IEnvironment } from "../../../services/registry/EnvironmentService";
import { getComparator, Order, stableSort } from "../../../utils/Table";
import { EnvironmentStateIndicator } from "./EnvironmentStateIndicator";
import Typography from "@mui/material/Typography";

interface HeadCell {
  id: keyof IEnvironment;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "Name",
    padding: "none",
  },
  {
    id: "stage",
    label: "Environment Type",
  },
  {
    id: "baseURL",
    label: "Base URL",
  },
  {
    id: "creationDate",
    label: "Creation Date",
    align: "right",
  },
  {
    id: "state",
    label: "State",
    align: "right",
  },
];

interface EnvironmentsTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IEnvironment) => void;
  order: Order;
  orderBy: string;
}

const EnvironmentsTableHead = ({ order, orderBy, onRequestSort }: EnvironmentsTableHeadProps) => {
  const createSortHandler = (property: keyof IEnvironment) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface EnvironmentsTableToolbarProps {
  onNewButtonClick: () => void;
  onRefreshButtonClick: () => void;
}

const EnvironmentsTableToolbar = ({ onNewButtonClick, onRefreshButtonClick }: EnvironmentsTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={onNewButtonClick}>
        New
      </Button>
      <Button variant="outlined" startIcon={<RefreshIcon />} onClick={onRefreshButtonClick} style={{ marginLeft: 8 }}>
        Refresh
      </Button>
    </Toolbar>
  );
};

export interface EnvironmentsTableProps {
  rows: IEnvironment[];
  selectedItem: IEnvironment | undefined;
  setSelectedItem: Dispatch<SetStateAction<IEnvironment | undefined>>;
  onNewButtonClick: () => void;
  onRefreshButtonClick: () => void;
  onPageChange: (page: number, rowsPerPage: number, visibleRows: IEnvironment[]) => void;
}

export const EnvironmentsTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onNewButtonClick,
  onRefreshButtonClick,
  onPageChange,
}: EnvironmentsTableProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IEnvironment>("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [includeTerminated, setIncludeTerminated] = useState(false);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IEnvironment) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const selectionChangeHandler = (event: ChangeEvent<HTMLInputElement>, row: IEnvironment) => {
    setSelectedItem(event.target.checked ? row : undefined);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredRows = useMemo(() => {
    return rows.filter((t) => includeTerminated || t.terminationDate === undefined);
  }, [rows, includeTerminated]);

  const visibleRows = useMemo(() => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return stableSort(filteredRows as any, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    onPageChange(page, rowsPerPage, visibleRows);
  }, [onPageChange, page, rowsPerPage, visibleRows]);

  const isSelected = (row: IEnvironment, selectedItem: IEnvironment | undefined) =>
    selectedItem !== undefined && row.name === selectedItem.name;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnvironmentsTableToolbar onNewButtonClick={onNewButtonClick} onRefreshButtonClick={onRefreshButtonClick} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <colgroup>
              <col style={{ width: "3%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "23%" }} />
              <col style={{ width: "17%" }} />
            </colgroup>
            <EnvironmentsTableHead order={order} orderBy={orderBy} onRequestSort={requestSortHandler} />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `environments-table-checkbox-${index}`;
                const creationDate = DateTime.fromSeconds(row.creationDate).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                );
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name}
                    selected={isSelected(row, selectedItem)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected(row, selectedItem)}
                        onChange={(event) => selectionChangeHandler(event, row)}
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.name + (row.isDefault ? " (default)" : "")}
                    </TableCell>
                    <TableCell>
                      {getEnvironmentStageData(row.stage)[2]} (Stage: {row.stage})
                    </TableCell>
                    <TableCell align="left">{row.baseURL}</TableCell>
                    <TableCell align="right">{creationDate}</TableCell>
                    <TableCell align="right">
                      <EnvironmentStateIndicator state={row.state || EnvironmentState.UNKNOWN} />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
            <Checkbox
              color="primary"
              checked={includeTerminated}
              onChange={() => setIncludeTerminated(!includeTerminated)}
            />
            <Typography variant="body2">Show terminated environments</Typography>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={pageChangeHandler}
            onRowsPerPageChange={rowsPerPageChangeHandler}
          />
        </div>
      </Paper>
    </Box>
  );
};
