import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { FormControlLabel, Switch } from "@mui/material";
import { ChangeEvent, useRef, useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { validateStringField } from "../../utils/Validation";

const BatchOperationsMenuFormField = (props: Omit<TextFieldProps, "variant">) => {
  return <TextField autoFocus margin="dense" fullWidth variant="standard" {...props} />;
};

export interface BatchOperationsMenuProps {
  visible: boolean;
  onClose: () => void;
  onResetUserAgreement: (playerIds: string[] | undefined) => Promise<void>;
}

export const BatchOperationsMenu = ({ visible, onClose, onResetUserAgreement }: BatchOperationsMenuProps) => {
  const refPlayerIds = useRef<TextFieldProps>(null);

  const [applyToAllPlayers, setApplyToAllPlayers] = useState(false);
  const [playerIdsError, setPlayerIdsError] = useState<string | undefined>(undefined);

  const allPlayersSwitchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApplyToAllPlayers(event.target.checked);
  };

  const validatePlayerIds = (value: string) => validateStringField(value, setPlayerIdsError, 1, 9999);
  const playerIdsChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    playerIdsError && validatePlayerIds(event.target.value);

  const runCallbackIfValidForm = async (callback: (playerIds: string[] | undefined) => Promise<void>) => {
    if (!refPlayerIds.current) {
      return;
    }

    const csvPlayerIds = validatePlayerIds(refPlayerIds.current.value as string);
    if (!csvPlayerIds) {
      return;
    }

    const playerIds = csvPlayerIds.split(",");
    await callback(playerIds);
  };

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>Batch Operations (for all players)</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            control={<Switch checked={applyToAllPlayers} size="small" onChange={allPlayersSwitchHandler} />}
            label="All players"
          />
          {applyToAllPlayers === false && (
            <BatchOperationsMenuFormField
              inputRef={refPlayerIds}
              id="playerIds"
              label="Player IDs"
              type="text"
              error={playerIdsError !== undefined}
              helperText={playerIdsError || "Player IDs to target (comma separated)"}
              onChange={playerIdsChangeHandler}
            />
          )}
          <Button
            variant="contained"
            onClick={async () => {
              if (applyToAllPlayers) {
                await onResetUserAgreement(undefined);
              } else {
                await runCallbackIfValidForm(onResetUserAgreement);
              }
            }}
            sx={{ marginTop: 2 }}
          >
            Reset User Agreement
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
